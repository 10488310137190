import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import logoSrc from 'assets/logo.svg';
import SVG from 'react-inlinesvg';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const Header = styled.div`
  display: flex;
  backround: white;
  height: 10rem;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
`;

const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--light_pink);
  height: 100%;
`;

export const Auth = ({ children }) => (
  <Container>
    <Header>
      <SVG src={logoSrc} />
    </Header>
    <CardWrapper>{children}</CardWrapper>
  </Container>
);

Auth.propTypes = {
  children: PropTypes.shape().isRequired,
};

export default Auth;
