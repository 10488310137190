
const BASE_URL = process.env.GATSBY_SERVER_HOST;

function request(method, endpoint, data, headers) {
  let sendData = null;
  if (method !== 'GET') sendData = JSON.stringify(data);
  const sendHeaders = {
    ...headers,
    'Content-Type': 'application/json',
  };

  return fetch(`${BASE_URL}${endpoint}`, {
    method,
    headers: sendHeaders,
    body: sendData,
  });
}

function _resetPassword(pass, token) {
  const data = {
    user: {
      password: pass,
      reset_password_token: token,
    },
  };
  return request('PUT', 'users/password.json', data);
}

function _confirmAccount(token) {
  return request('GET', `users/confirmation.json?confirmation_token=${token}`);
}


// Action Creators
export async function confirmAccount(token) {
  let response = null;

  if (!token || token === 'confirm') {
    return { text: 'Para continuar, insira um(a) Token de confirmação válido.' };
  }

  try {
    response = await _confirmAccount(token);
  } catch (error) {
    return { text: error.message };
  }

  if (response.status === 422) {
    const data = await response.json();

    return { text: data.error };
  }

  return {
    ok: true,
    text: 'A sua conta foi confirmada com sucesso.',
  };
}

export async function resetPassword(pass, token) {
  let response = null;

  if (!token || token === 'reset') {
    return { text: 'Para continuar, insira um(a) Token válido.' };
  }

  try {
    response = await _resetPassword(pass, token);
  } catch (error) {
    return { text: error.message };
  }

  if (response.status === 422) {
    const data = await response.json();

    return { text: data.error };
  }

  return {
    ok: true,
    text:
      'A sua senha foi mudada com sucesso. Pode agora voltar a entrar na aplicação.',
  };
}

