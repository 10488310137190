import React from 'react';
import PropTypes from 'prop-types';
import c from 'classnames';
import { compose, lifecycle, withState, withProps } from 'recompose';
import SVG from 'react-inlinesvg';
import styled from 'styled-components';

import Layout from 'components/layout';
import Card from 'components/styled/Card';
import Column from 'components/styled/Column';
import Auth from 'components/templates/Auth';

import wrappers from 'styles/_wrappers.module.scss';
import helpers from 'styles/_helpers.module.scss';

import successSrc from 'assets/images/email_confirm.svg';
import errorSrc from 'assets/images/email_confirm_error.svg';
import logoSrc from 'assets/images/logo_icon.svg';
import styles from 'styles/pages/confirm.module.scss';
import { confirmAccount } from 'core/api';
import stylesFunc from 'styles/pages/funcionalidades.module.scss';

const Col = Column({ alignCenter: true, center: true });

const Message = styled.p`
  margin-top: 1.4rem;
  font-size: 1.6rem;
  padding: 0rem 4rem;
  text-align: center;
`;

const TopStatus = styled.p`
  margin-top: 4rem;
  font-size: 1.6rem;
  padding: 0rem 4rem;
  text-align: center;
  font-weight: bold;
  @media (min-width: 750px) {
    font-size: 2.2rem;
  }
`;

const Container = styled.div`
  @media (min-width: 750px) {
    padding: 15rem 2rem 10rem 2rem;
  }
  padding: 10rem 2rem;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
`;

const successMessage = 'Obrigado por ser cadastrar no Anotado!';
const errorMessage = 'Ooops! Algo correu mal!';

const AccountConfirmationScreen = props => (
  <Layout location={props.location} title="Confirmação de conta">
    <Container>
      <SVG src={logoSrc} />
      <h2 className={helpers.mt12}>Simples. Prático. Completo.</h2>
      <h1 className={c(helpers.mt10, helpers.mb30)}>direito anotado</h1>
      <Card className={c(wrappers.card_auth_wrapper, styles.card_auth_wrapper)}>
        <Col>
          <SVG
            src={props.status != null && !props.status.ok ? errorSrc : successSrc}
            className={styles.logo}
          />
          <TopStatus>
            {props.status && props.status.ok ? successMessage : errorMessage}
          </TopStatus>
          <Message>{props.status && props.status.text}</Message>
        </Col>
      </Card>
    </Container>
  </Layout>
);

AccountConfirmationScreen.propTypes = {
  error: PropTypes.shape(),
};

export default compose(
  withState('status', 'setStatus', {}),
  withProps(props => ({
    confirmAccount: async () => {
      const url = new URL(props.location.href);
      const token = url.searchParams.get('token');
      props.setStatus(await confirmAccount(token));
    },
  })),
  lifecycle({
    componentDidMount() {
      this.props.confirmAccount();
    },
  }),
)(AccountConfirmationScreen);
